<template>
  <div :class="containerClass">
    <page-header title="Inquiries" />

    <div class="page-section">
      <div>
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />

                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <b-table
            :fields="tableColumns"
            :items="inquiries"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap sticky-lcol-table"
            hover
            responsive
            no-local-sorting
            show-empty
            empty-text="No inquiries found."
            @sort-changed="onSortChange"
            @row-clicked="openDetails"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #empty>
              <div class="text-center">No inquiries found.</div>
            </template>

            <template #cell(first_name)="data"> {{ data.item.first_name }} {{ data.item.last_name }}</template>

            <template #cell(email)="data"> {{ data.item.email }}</template>
            <template #cell(phone)="data"> {{ data.item.phone }}</template>
            <template #cell(types)="data"> {{ $t(`programInquiryTypes.${data.item.types}`) }}</template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a :href="`mailto:${data.item.email}`"
                ><i class="material-icons mr-1" v-b-popover.hover.left title="Email requestee">email</i></a
              >
              <a class="ml-2" :href="`tel:${data.item.phone}`" v-b-popover.hover.left title="Call requestee"
                ><i class="material-icons mr-1">phone</i></a
              >
              <a
                v-if="!showSMS"
                class="ml-2"
                :href="`sms:${data.item.phone}`"
                v-b-popover.hover.left
                title="SMS requestee"
                ><i class="material-icons mr-1">sms</i></a
              >
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalInquiries"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="info-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import PageHeader from '@/components/Ui/PageHeader.vue';
export default {
  components: {
    MdIcon,
    Pagination,
    PageHeader,
  },
  extends: Page,

  data() {
    return {
      title: 'Program Inquiries',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalInquiries: 0,
      searchTerm: '',
      inquiries: [],
      showDetailModal: false,
      contact: {},
      infoData: {},
      showInfoModal: false,
      filteredProgram: null,
      allPrograms: [],
      areProgramsLoading: false,
      isFileDownloading: false,
      showSMS: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Inquiries', active: true },
      ];
    },
    disableExportBtn() {
      return !this.totalInquiries || this.isFileDownloading;
    },
    tableColumns() {
      return [
        { key: 'first_name', label: 'Name', tdClass: 'clickable-item' },
        { key: 'email', label: 'Email', tdClass: 'clickable-item' },
        { key: 'phone', label: 'Phone', tdClass: 'clickable-item' },
        {
          key: 'created_at',
          label: 'Contacted at',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? formatDateTime(value) : value),
        },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('inquiries', ['getInquiries']),
    get,

    async fetchInquiries(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getInquiries({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.inquiries = response.data.results;
      this.currentPage = pageNum;
      this.totalInquiries = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchInquiries(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchInquiries();
    },

    onSearch() {
      this.debouncedSearchInfoContacts(this);
    },

    openDetails(data) {
      this.$router.push({ name: 'org-inquiry-details', params: { id: data.id } });
    },

    debouncedSearchInfoContacts: debounce((vm) => {
      vm.fetchInquiries();
    }, 500),
  },

  async mounted() {
    this.showSMS = navigator.userAgent.includes('Win');
    if (this.$route.query.program) {
      this.filteredProgram = parseInt(this.$route.query.program);
    }
    this.fetchInquiries();
  },
};
</script>
