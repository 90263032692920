<template>
  <div :class="containerClass">
    <page-header title="Inquiry Details" show-back>
      <template #button>
        <b-dropdown text="Contact" variant="primary" class="ml-2">
          <b-dropdown-item :href="`mailto:${get(inquiry, 'email')}`"
            ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
          >
          <b-dropdown-item :href="`tel:${get(inquiry, 'phone')}`"
            ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
          >
          <b-dropdown-item v-if="!showSMS" :href="`sms:${get(inquiry, 'phone')}`"
            ><i class="material-icons mr-1">sms</i>SMS</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </page-header>

    <div class="page-section">
      <card title="Inquirer Info">
        <!-- <b-table stacked :items="[inquirerInfo]"></b-table> -->

        <b-table-simple striped v-if="isLoading">
          <b-tbody>
            <b-tr v-for="n in 5" :key="n">
              <b-td style="width: 35%"><b-skeleton width="100%"></b-skeleton> </b-td>
              <b-td style="width: 65%"><b-skeleton width="100%"></b-skeleton> </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <b-table-simple striped v-else>
          <b-tbody>
            <b-tr v-for="(value, key) in inquirerInfo" :key="key">
              <b-th style="width: 35%">{{ key }}</b-th>
              <b-td style="width: 65%">{{ value }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </card>

      <card title="Message">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </template>

          <div v-safe-html="message" />
        </b-skeleton-wrapper>
      </card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { get, trim } from 'lodash';
import { formatDateTime } from '@/common/utils';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Card from '@/components/Ui/Card.vue';

export default {
  extends: Page,
  components: { PageHeader, Card },
  data() {
    return {
      title: 'Inquiry Details',
      inquiry: null,
      isLoading: false,
      windowWidth: window.innerWidth,
      message: '',
      chatVisible: true,
      showSMS: false,
      inquirerInfo: {
        Name: '',
        Email: '',
        Phone: '',
        'Permission to Text': '',
        'Contacted At': '',
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobileScreen() {
      return this.windowWidth <= 420;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('home'), to: { name: 'org-inquiries-list' } },
        { text: 'Inquiries', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('inquiries', ['getInquiry']),
    get,
    formatDateTime,
    formatText(string) {
      const result = trim(string, '\n');
      return result.replace(/\n/g, '<br />');
    },
    async fetchInquiry() {
      this.isLoading = true;

      try {
        const data = await this.getInquiry(this.$route.params.id);
        this.inquiry = data;

        this.message = '';
        let htmlMessage = this.inquiry.comments;
        const htmlEntities = [
          { regex: '&', entity: '&amp;' },
          { regex: '>', entity: '&gt;' },
          { regex: '<', entity: '&lt;' },
        ];
        htmlEntities.forEach((ent) => {
          const reg = new RegExp(ent.regex, 'g');
          htmlMessage = htmlMessage.replace(reg, ent.entity);
        });
        this.message = htmlMessage.replace(/(?:\r\n|\r|\n)/g, '<br>');

        this.inquirerInfo = {
          Name: `${get(this.inquiry, 'first_name')} ${get(this.inquiry, 'last_name')}`,
          Email: get(this.inquiry, 'email'),
          Phone: get(this.inquiry, 'phone'),
          'Permission to Text': get(this.inquiry, 'is_text_allowed') ? 'Yes' : 'No',
          'Contacted At': this.formatDateTime(get(this.inquiry, 'created_at')),
        };
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.$router.push({ name: 'org-inquiries-list' });
      }
      this.isLoading = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    this.showSMS = navigator.userAgent.includes('Win');
    window.addEventListener('resize', this.handleResize);

    try {
      if (this.$route.params.id) {
        this.fetchInquiry();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
</style>